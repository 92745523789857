import React, { ReactNode } from 'react';
import { Button, ButtonProps } from 'ui';
import { useLogout, useSession } from '../../../hooks';
import Image from 'next/image';
interface OnboardingLayoutProps {
  children: ReactNode;
  bottomBarActions?: ButtonProps[];
  showCurrentOrg?: boolean;
}

const OnboardingLayout = ({ children, bottomBarActions }: OnboardingLayoutProps) => {
  const logout = useLogout();
  const { userId } = useSession();

  return (
    <div className='w-full h-screen flex flex-col divide-y overflow-hidden'>
      <div className='w-full flex justify-between items-center py-3 px-6 font-semibold'>
        <div className='flex items-center gap-3'>
          <Image width={40} height={40} alt='' src={'/svg/logo.svg'} />
          <div className='text-xl'>Entendre</div>
        </div>
        {!!userId?.length && (
          <Button data-cy='onboardingWorkspace_logoutButton' onClick={logout} emphasis='medium' label='Logout' />
        )}
      </div>
      <div className='flex-grow overflow-auto'>{children}</div>
      {bottomBarActions && (
        <div className='py-3 px-6 w-full flex flex-row-reverse item-center gap-x-2'>
          {bottomBarActions.map((action, i) => (
            <Button {...action} key={i} />
          ))}
        </div>
      )}
    </div>
  );
};

export default OnboardingLayout;
